import { useQuery } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import * as chartjs from 'chart.js'
import { useIntl } from 'gatsby-plugin-intl'
import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import { Bar, ChartData } from 'react-chartjs-2'

import { sortByRankDesc } from '../../utils/utils'
import AgencyRow from './agency-row'
import CardsSkeleton from './cards-skeleton'
import SeeAll from './see-all'

const HomeCards = (): JSX.Element => {
  const { loading, error, data } = useQuery(APOLLO_QUERY)
  const [sortedAgencies, setSortedAgencies] = useState([])
  const [datasets, setDatasets] = useState([])

  const [labels, setLabels] = useState([])
  const [showChart, setShowChart] = useState(false)

  const chartAfterUpdate = (chart) => {
    Object.entries(chart?.config?.data?.datasets?.[0]?._meta || {}).forEach(meta => {
      (meta?.[1] as any)?.data.forEach((data, idx) => {
        const path = chart?.config?.data?.datasets?.[0]?.data[idx]?.meta?.icon?.publicUrl
        if (!path) return
        const url = `${process.env.GATSBY_IMAGE_API}${path}`
        const img = new Image(30, 30)
        img.src = url
        data._model.hitRadius = 30
        data._model.pointStyle = img
      })
    })

  }

  useEffect(() => {
    const chartAgenciesTemp = [...sortedAgencies].slice(0, 15)

    const datasetsTemp = [{
      label: 'Dataset',
      type: 'scatter',
      data: chartAgenciesTemp.map((agency, i) => ({
        x: i * 10,
        y: agency?.verdicts?.[0]?.total,
        meta: agency,
      }))
    }, {
      label: 'Dataset 2',
      type: 'bar',
      backgroundColor: '#0eb48d',
      barThickness: 30,
      data: chartAgenciesTemp.map((agency, i) => ({ y: agency?.verdicts?.[0]?.total, meta: agency }))
    }]

    setLabels(chartAgenciesTemp.map((agency) => agency.name))

    setDatasets(datasetsTemp)

    if (sortedAgencies.length) {
      setShowChart(true)
    }
  }, [sortedAgencies.length, setLabels, setDatasets, setShowChart])

  const intl = useIntl()

  useEffect(() => {
    if (data && data.allMediaAgencies.length) {
      const sorted = [...data.allMediaAgencies].sort(sortByRankDesc)
      setSortedAgencies(sorted)
    }
  }, [data])

  const renderError = error ? <div>{intl.formatMessage({ id: 'general.errorLoading' })}</div> : <></>
  const renderSkeleton = loading ? <CardsSkeleton /> : <></>

  const plugins = [{
    afterUpdate: chartAfterUpdate,
  }]

  const options: chartjs.ChartOptions = {
    tooltips: {
      callbacks: {
        title: (items, data) => (data.datasets[items[0].datasetIndex].data[items[0].index] as any)?.meta?.name,
        label: (item, data) => (data.datasets[item.datasetIndex].data[item.index] as any)?.meta?.verdicts?.[0]?.total,
      }
    },
    legend: {
      display: false,
    },
    scales: {
      yAxes: [{
        offset: true,
        ticks: {
          min: 0,
          max: 55,
          suggestedMax: 56,
          suggestedMin: 0,
          stepSize: 5,
        },
        scaleLabel: {
          display: true,
          labelString: intl.formatMessage({ id: 'general.ic' })
        }
      }],
    }
  };

  const chartData: ChartData<chartjs.ChartData> = {
    labels: labels,
    datasets
  };

  const renderChart = showChart ? (<div className="mb-4 card" style={{ overflowX: 'scroll' }}>
    <div className="d-none d-lg-block p-4">
      <Bar
        options={options}
        data={chartData}
      />
    </div>
    <div className="d-block d-lg-none" style={{ position: 'relative', width: '1000px' }}>
      <Bar
        height={500}
        width={1600}
        options={options}
        data={chartData}
      />
    </div>
  </div>) : null

  return (
    <>
      <Container className="home-page py-5">
        <div className="text-center mb-5">
          <h3 id="topMediaAgencies">{intl.formatMessage({ id: 'home.topAgencies' })}</h3>
        </div>
        {renderError}
        {renderSkeleton}
        {renderChart}

        <div className="">
          <AgencyRow agencies={sortedAgencies.slice(0, 6)} />
        </div>
        <SeeAll />
      </Container>
    </>
  )
}
const APOLLO_QUERY = gql`
  {
    allMediaAgencies {
      id
      name
      url
      photo {
        publicUrl
      }
      icon {
        publicUrl
      }
      createdAt
      updatedAt
      path
      verdicts(sortBy: verdictDate_DESC) {
        id
        createdAt
        verdictDate
        total
        credibilityLabel
      }
    }
  }
`
export default HomeCards
