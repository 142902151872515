import React from "react"
import { Row } from "react-bootstrap"
import { useIntl, Link } from "gatsby-plugin-intl"

const SeeAll = (): JSX.Element => {
  const intl = useIntl()

  return (
    <Row noGutters className="d-flex justify-content-center mt-5 py-lg-5 py-0">
      <Link to="/media-agencies/" className="see-all-button">
        {intl.formatMessage({ id: 'home.seeAll' })}
      </Link>
    </Row>
  )
}

export default SeeAll
