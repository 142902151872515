import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { useIntl, Link } from "gatsby-plugin-intl"

const Chart = (): JSX.Element => {
  const intl = useIntl()

  return (
    <Row className="bg-white py-5" noGutters>
      <Container id="homeAboutSection">
        <Row>
          <Col
            lg={6}
            xs={12}
            className="d-flex flex-column justify-content-center"
          >
            <div className="about-row">
              <span className="green-text">{intl.formatMessage({ id: 'navbar.about' })}</span>
              <div className="title">
                {intl.formatMessage({ id: 'home.evolution' })}
              </div>
              <div className="desciption">
                {intl.formatMessage({ id: 'home.appDescription' })}
              </div>
              <div className="more-link-container">
                <Link to="/about/" className="more-link">
                  {intl.formatMessage({ id: 'home.more' })}
                  <img height={14} src="/images/arrow-left.svg" />
                </Link>
              </div>
            </div>
          </Col>
          <Col lg={6} xs={12}>
            <img
              height={640}
              src="/images/about-section-phone.png"
              className="d-block m-lg-0 m-auto about-section-img"
            />
          </Col>
        </Row>
      </Container>
    </Row>
  )
}

export default Chart
