import { useIntl } from "gatsby-plugin-intl"
import React from 'react'
import Header from '../header'
import HomeCards from './cards'
import Chart from './chart'

const Home = (): JSX.Element => {
  const intl = useIntl()

  return (
    <>
      <Header
        title="Media RADAR"
        subtitle={intl.formatMessage({ id: 'home.subtitle' })}
        icon={true}
        customStyle={{ padding: '6rem 0 4rem 0' }}
      />
      <HomeCards />
      <Chart />
    </>
  )
}

export default Home
