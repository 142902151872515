import React from "react"

import Home from "../components/home/home"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { useIntl } from "gatsby-plugin-intl"

const IndexPage = (): JSX.Element => {
  const intl = useIntl()

  return (<Layout>
    <SEO
      title="Media RADAR"
      description={intl.formatMessage({ id: 'home.subtitle' })}
    />
    <Home />
  </Layout>)
}

export default IndexPage
