import React from "react"
import AgencyCard from "./agency-card"
import { MediaAgency } from "../../types/MediaAgency"
import { Col, Row } from "react-bootstrap"

const AgencyRow = ({
  agencies,
}: {
  agencies: Array<Partial<MediaAgency>>
}): JSX.Element => {
  return (
    <Row>
      {agencies.map((agency, index) => (
        <Col lg={4} md={6} xs={12} key={index}>
          <AgencyCard key={agency.name} agency={agency} credibilityBadge={false} />
        </Col>
      ))}
    </Row>
  )
}

export default AgencyRow
